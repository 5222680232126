
.carousel-h1 {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
}

.previousButton, .nextButton {
    opacity: 10%;
}
  